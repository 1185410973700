import { useLazyQuery, useMutation } from '@apollo/client'
import { useToast, Card, Text } from '@chakra-ui/react'
import { tw } from '@nickeltech/brise'
import currency from 'currency.js'
import { useEffect, useState } from 'react'
import {
  ACHForm,
  AddressInput,
  Button,
  Column,
  Form,
  Row,
  SelectInput,
  US_STATES,
  calculatePaymentSummaryForMerchant,
  FormikValidatingInput,
} from 'ui'
import ContainerProps from 'ui/src/types'
import {
  BaseSchema,
  RecipientDetailsValidationSchemaType,
  useSendPaymentStore,
} from './SendPaymentRouter'

import {
  AddVendorDocument,
  GetBankRoutingNumberDocument,
  GetBankRoutingNumberQuery,
  UpdateVendorCheckPayoutMethodDocument,
  UpdateVendorPayoutMethodDocument,
  Vendor,
} from '../../../operations-types'
import { SlimSelectVendor } from '../VendorDetails'
import { FormSection } from './Base'
import { RecipientDetailsValidationSchema } from './SendPaymentRouter'

type Props = {}

const DueOnText = tw.div<ContainerProps>`
    text-xs
    text-gray-500
    font-normal
`

const Recipient = (_: Props) => {
  const {
    setPage,
    setVendorId,
    vendorPayoutMethodId,
    setVendorPayoutMethodId,
    setRecipientDetails,
    setPaymentSummary,
    sendMoneyAmountResult,
    selectedSavedPaymentMethod,
    bill,
    vendorFromStore,
    setInitialRecipients,
  } = useSendPaymentStore((state) => ({
    setPaymentSummary: state.setPaymentSummary,
    paymentSummary: state.paymentSummary,
    clearSelectedSavedPaymentMethod: state.clearSelectedSavedPaymentMethod,
    setPage: state.setPage,
    setVendorId: state.setVendorId,
    vendorPayoutMethodId: state.vendorPayoutMethodId,
    setVendorPayoutMethodId: state.setVendorPayoutMethodId,
    setRecipientDetails: state.setRecipientDetails,
    sendMoneyAmountResult: state.sendMoneyAmountResult,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
    setInitialRecipients: state.setInitialRecipients,
    bill: state.bill,
    vendorFromStore: state.vendor,
    initialRecipients: state.initialRecipients,
  }))

  const [addVendor, { error: vendorCreateError }] =
    useMutation(AddVendorDocument)

  const [updateVendorPayoutMethod, { loading: updateACHLoading }] = useMutation(
    UpdateVendorPayoutMethodDocument,
  )
  const [updateVendorCheckPayoutMethod, { loading: updateCheckLoading }] =
    useMutation(UpdateVendorCheckPayoutMethodDocument)

  const toast = useToast()
  const [vendor, setVendor] = useState<Vendor | null>(null)

  useEffect(() => {
    if (vendorCreateError) {
      toast({
        status: 'error',
        title: vendorCreateError?.message,
      })
    }
    if (vendorFromStore) {
      setVendor(vendorFromStore)
      setVendorId(vendorFromStore.id || '')
    }
  }, [vendorCreateError, bill])

  const [search, { loading: bankRoutingLoading }] =
    useLazyQuery<GetBankRoutingNumberQuery>(GetBankRoutingNumberDocument, {})

  const CHECK_FEE = 200

  const vendorAchPayoutMethod = vendor?.vendorPayoutMethods?.find(
    (x) => x?.type === 'ACH',
  )

  const vendorCheckPayoutMethod = vendor?.vendorPayoutMethods?.find(
    (x) => x?.type === 'CHECK',
  )

  return (
    <Form
      {...{
        className: 'w-full',
        validationSchema: RecipientDetailsValidationSchema,
        initialValues: {
          vendorName: vendor?.name || '',
          vendorEmail: vendor?.email || '',
          achDetails: {
            accountNumber: vendorAchPayoutMethod?.accountNumber || '',
            bankName: vendorAchPayoutMethod?.bankName || '',
            bankRoutingNumberLoading: vendorAchPayoutMethod ? true : false,
            routingNumber: vendorAchPayoutMethod?.routingNumber || '',
            confirmedAccountNumber: vendorAchPayoutMethod?.accountNumber || '',
            accountType: 'checking',
            recipientAddress: {
              streetAddress: vendorCheckPayoutMethod?.street || '',
              city: vendorCheckPayoutMethod?.city || '',
              state:
                US_STATES.states.find(
                  (x) =>
                    x.abbreviation === vendorCheckPayoutMethod?.state || '',
                )?.name || '',
              zipCode:
                vendorCheckPayoutMethod?.zip?.split('-')?.at(0) ||
                vendor?.zip ||
                '',
            },
          },

          paymentVia: vendorAchPayoutMethod
            ? 'ACH'
            : vendorCheckPayoutMethod
            ? 'check'
            : '',
        } as RecipientDetailsValidationSchemaType,
        validateOnChange: true,
        enableReinitialize: true,
        validateOnMount: true,
      }}
    >
      {(formik) => {
        return (
          <>
            <Column gap="medium" className="!w-[540px]" wGrow>
              <Card w="100%" className="portal-target" py={8} px={6}>
                <Column gap="medium">
                  {!vendorFromStore && !bill && (
                    <SlimSelectVendor
                      {...{
                        selectedValue: vendor || null,
                        onVendorSelect: async (vendor: Vendor | null) => {
                          await formik.resetForm()
                          await formik.validateForm()
                          setVendor(vendor || null)
                          setVendorId(vendor?.id || '')
                          await formik.setFieldValue(
                            'vendorName',
                            vendor?.name || '',
                          )
                          await setInitialRecipients(vendor?.recipients || [])
                          if (vendor) {
                            const achPayoutMethod = (
                              vendor.vendorPayoutMethods || []
                            ).find((x) => x?.type === 'ACH')

                            const checkPayoutMethod = (
                              vendor.vendorPayoutMethods || []
                            ).find((x) => x?.type === 'CHECK')

                            if (achPayoutMethod) {
                              setVendorPayoutMethodId(achPayoutMethod.id || '')
                              formik.setFieldValue('vendorName', vendor.name)
                              formik.setFieldValue('paymentVia', 'ACH')
                              formik.setFieldValue(
                                'achDetails.accountType',
                                achPayoutMethod?.accountType || 'checking',
                              )

                              formik.setFieldValue(
                                'achDetails.routingNumber',
                                achPayoutMethod?.routingNumber || '',
                              )

                              search({
                                variables: {
                                  bankRoutingNumber:
                                    achPayoutMethod?.routingNumber,
                                },
                              }).then((response) => {
                                if (
                                  response.data?.bankRoutingNumber
                                    ?.bankRoutingNumber?.bankName
                                )
                                  formik.setFieldValue(
                                    'achDetails.bankName',
                                    response.data.bankRoutingNumber
                                      .bankRoutingNumber.bankName,
                                  )
                              })

                              await formik.setFieldValue(
                                'achDetails.accountNumber',
                                achPayoutMethod?.accountNumber || '',
                              )

                              await formik.setFieldValue(
                                'achDetails.confirmedAccountNumber',
                                achPayoutMethod?.accountNumber || '',
                              )

                              await setPaymentSummary(
                                calculatePaymentSummaryForMerchant(
                                  sendMoneyAmountResult?.submittedAmountCents ||
                                    0,
                                  !!selectedSavedPaymentMethod?.card?.brand
                                    ? 'Credit Card'
                                    : 'ACH Transfer',
                                  'ACH',
                                ),
                              )
                            }

                            if (checkPayoutMethod) {
                              formik.setFieldValue(
                                'achDetails.recipientAddress.streetAddress',
                                (checkPayoutMethod?.street || '') +
                                  ' ' +
                                  (checkPayoutMethod?.street2 || ''),
                              )

                              formik.setFieldValue(
                                'achDetails.recipientAddress.city',
                                checkPayoutMethod?.city || '',
                              )

                              formik.setFieldValue(
                                'achDetails.recipientAddress.state',
                                US_STATES.states.find(
                                  (x) =>
                                    x.abbreviation === checkPayoutMethod?.state,
                                )?.name || '',
                              )

                              formik.setFieldValue(
                                'achDetails.recipientAddress.zipCode',
                                checkPayoutMethod?.zip?.split('-')?.at(0) || '',
                              )

                              if (!achPayoutMethod) {
                                await setPaymentSummary(
                                  calculatePaymentSummaryForMerchant(
                                    sendMoneyAmountResult?.submittedAmountCents ||
                                      0,
                                    !!selectedSavedPaymentMethod?.card?.brand
                                      ? 'Credit Card'
                                      : 'ACH Transfer',
                                    'Check',
                                  ),
                                )
                              }
                            }
                          }

                          await formik.validateForm()
                        },
                        onNewVendor: async (newVendor) => {
                          const data = await addVendor({
                            variables: { name: newVendor },
                          })

                          setVendor(data.data?.addVendor?.vendor || null)
                          setVendorId(data.data?.addVendor?.vendor?.id || '')

                          formik.setFieldValue(
                            'vendorName',
                            data?.data?.addVendor?.vendor?.name,
                          )

                          formik.setFieldValue('paymentVia', '')
                          formik.setFieldValue('achDetails.accountType', '')

                          formik.setFieldValue('achDetails.routingNumber', '')

                          formik.setFieldValue('achDetails.accountNumber', '')
                          formik.setFieldValue(
                            'achDetails.confirmedAccountNumber',
                            '',
                          )

                          formik.setFieldValue(
                            'achDetails.recipientAddress.streetAddress',
                            '',
                          )
                          formik.setFieldValue(
                            'achDetails.recipientAddress.city',
                            '',
                          )

                          formik.setFieldValue(
                            'achDetails.recipientAddress.state',
                            undefined,
                          )

                          formik.setFieldValue('achDetails.bankName', '')

                          formik.setFieldValue(
                            'achDetails.recipientAddress.zipCode',
                            '',
                          )
                        },
                      }}
                    />
                  )}

                  {vendor && vendorFromStore && bill && (
                    <>
                      <FormSection title="Name">
                        <Text fontSize="lg" fontWeight="semibold">
                          {vendor.name}
                        </Text>
                      </FormSection>
                      <FormSection title="Email">
                        <FormikValidatingInput
                          fieldName="vendorEmail"
                          placeholder="Enter your vendor's email address"
                          fontSize="md"
                          size="md"
                        />
                      </FormSection>
                    </>
                  )}

                  {vendor && !vendorFromStore && (
                    <FormSection title="Email">
                      <FormikValidatingInput
                        fieldName="vendorEmail"
                        placeholder="Enter your vendor's email address"
                        fontSize="md"
                        size="md"
                      />
                    </FormSection>
                  )}

                  {vendor && (
                    <>
                      <FormSection title="Delivery Method">
                        <Row grow className="relative">
                          <SelectInput
                            {...{
                              isClearable: false,
                              hasSuccess: true,
                              className: 'border rounded-md p-[1px]',
                              onChange: async (e) => {
                                formik.setFieldValue('paymentVia', e.value)

                                await setPaymentSummary(
                                  calculatePaymentSummaryForMerchant(
                                    sendMoneyAmountResult?.submittedAmountCents ||
                                      0,
                                    !!selectedSavedPaymentMethod?.card?.brand
                                      ? 'Credit Card'
                                      : 'ACH Transfer',
                                    e.value === 'check' ? 'Check' : 'ACH',
                                  ),
                                )

                                if (e.value === 'check') {
                                  const checkPayoutMethod = (
                                    vendor.vendorPayoutMethods || []
                                  ).find((x) => x?.type === 'CHECK')

                                  if (checkPayoutMethod) {
                                    setVendorPayoutMethodId(
                                      checkPayoutMethod?.id || '',
                                    )
                                  }
                                }

                                if (e.value === 'ACH') {
                                  const achPayoutMethod = (
                                    vendor.vendorPayoutMethods || []
                                  ).find((x) => x?.type === 'ACH')

                                  if (achPayoutMethod) {
                                    setVendorPayoutMethodId(
                                      achPayoutMethod?.id || '',
                                    )
                                  }
                                }
                              },
                              styles: {
                                clearIndicator: (base) => ({
                                  ...base,
                                  display: 'none',
                                }),
                              },
                              name: 'paymentVia',
                              options: [
                                {
                                  label: 'ACH',
                                  value: 'ACH',
                                },
                                {
                                  label: 'Check',
                                  value: 'check',
                                },
                              ],
                            }}
                          />
                          <DueOnText className="absolute right-[40px] top-[12px]">
                            {formik.values?.paymentVia === 'check'
                              ? `You pay ${currency(CHECK_FEE, {
                                  fromCents: true,
                                }).format()} for delivery | 3-7 business days`
                              : formik.values?.paymentVia === 'ACH'
                              ? '1 to 3 business days'
                              : ''}
                          </DueOnText>
                        </Row>
                      </FormSection>

                      {formik.values?.paymentVia === 'ACH' &&
                      formik.values.vendorName ? (
                        <ACHForm
                          loading={bankRoutingLoading}
                          isDemo={false}
                          routingNumber={
                            formik.values?.achDetails?.routingNumber || ''
                          }
                          accountNumber={
                            formik.values?.achDetails?.accountNumber || ''
                          }
                          confirmedAccountNumber={
                            formik.values?.achDetails?.confirmedAccountNumber ||
                            ''
                          }
                          bankName={formik.values?.achDetails?.bankName}
                          onChangeRoutingNumber={(routingNumber: string) => {
                            setVendorPayoutMethodId(undefined)
                            formik.setFieldValue(
                              'achDetails.bankName',
                              undefined,
                            )
                            if (routingNumber.length === 9) {
                              search({
                                variables: {
                                  bankRoutingNumber: routingNumber,
                                },
                              }).then((response) => {
                                if (
                                  response.data?.bankRoutingNumber
                                    ?.bankRoutingNumber?.bankName
                                )
                                  formik.setFieldValue(
                                    'achDetails.bankName',
                                    response.data.bankRoutingNumber
                                      .bankRoutingNumber.bankName,
                                  )
                              })
                            }
                            formik.setFieldValue(
                              'achDetails.routingNumber',
                              routingNumber,
                            )
                            formik.setFieldTouched(
                              'achDetails.routingNumber',
                              true,
                            )
                          }}
                          onChangeAccountNumber={(accountNumber: string) => {
                            setVendorPayoutMethodId(undefined)
                            formik.setFieldValue(
                              'achDetails.accountNumber',
                              accountNumber,
                            )
                            formik.setFieldTouched(
                              'achDetails.accountNumber',
                              true,
                            )
                          }}
                          onChangeConfirmAccountNumber={(
                            confirmAccountNumber: string,
                          ) => {
                            setVendorPayoutMethodId(undefined)
                            formik.setFieldValue(
                              'achDetails.confirmedAccountNumber',
                              confirmAccountNumber,
                            )
                            formik.setFieldTouched(
                              'achDetails.accountNumber',
                              true,
                            )
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {formik.values?.paymentVia === 'check' ? (
                        <AddressInput
                          {...{
                            valueKey: 'achDetails.recipientAddress',
                            apiKey: import.meta.env.VITE_SMARTY_STREETS_KEY,
                            label: 'Recipient Address',
                            onChangeAddress: (address: string) => {
                              setVendorPayoutMethodId(undefined)
                            },
                            onChangeCity(city) {
                              setVendorPayoutMethodId(undefined)
                            },
                            onChangeState(state) {
                              setVendorPayoutMethodId(undefined)
                            },
                            onChangeZip(zip) {
                              setVendorPayoutMethodId(undefined)
                            },
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </Column>
              </Card>

              <Button
                w="100%"
                label="Next"
                isLoading={updateACHLoading || updateCheckLoading}
                iconPosition="right"
                isDisabled={
                  ((Object.keys(formik.errors).length > 0 ||
                    formik.values.paymentVia.length === 0) &&
                    !BaseSchema.isValidSync(formik.values)) ||
                  !formik.values.vendorEmail
                }
                onClick={async () => {
                  setRecipientDetails(formik.values)

                  if (vendorPayoutMethodId) {
                    setPage('review')
                    return
                  }
                  if (formik.values.paymentVia === 'ACH') {
                    const vendorPayoutMethod = await updateVendorPayoutMethod({
                      variables: {
                        vendorId: vendor?.id || '',
                        accountNumber:
                          formik.values.achDetails.accountNumber || '',
                        routingNumber:
                          formik.values.achDetails.routingNumber || '',
                        accountType: formik.values.achDetails.accountType || '',
                      },
                    })

                    if (
                      vendorPayoutMethod.data?.updateVendorPayoutMethod?.error
                    ) {
                      toast({
                        status: 'error',
                        description:
                          vendorPayoutMethod.data?.updateVendorPayoutMethod
                            ?.error.message,
                        duration: 9000,
                        isClosable: true,
                      })
                      await formik.resetForm()
                      if (vendorAchPayoutMethod?.id) {
                        setVendorPayoutMethodId(vendorAchPayoutMethod.id)
                      }

                      return
                    }
                    setVendorPayoutMethodId(
                      vendorPayoutMethod.data?.updateVendorPayoutMethod
                        ?.vendorPayoutMethod?.id || '',
                    )
                  } else if (formik.values.paymentVia === 'check') {
                    const vendorPayoutMethod =
                      await updateVendorCheckPayoutMethod({
                        variables: {
                          vendorId: vendor?.id || '',
                          street:
                            formik.values.achDetails.recipientAddress
                              .streetAddress || '',
                          city:
                            formik.values.achDetails?.recipientAddress?.city ||
                            '',
                          state:
                            formik.values.achDetails.recipientAddress.state ||
                            '',
                          zip:
                            formik.values.achDetails.recipientAddress.zipCode ||
                            '',
                        },
                      })
                    setVendorPayoutMethodId(
                      vendorPayoutMethod.data?.updateVendorCheckPayoutMethod
                        ?.vendorPayoutMethod?.id || '',
                    )
                  }

                  setPage('review')
                }}
              />
            </Column>
          </>
        )
      }}
    </Form>
  )
}

export default Recipient
