import { CreditCardOutlined } from '@ant-design/icons'
import { Text, Tooltip } from '@chakra-ui/react'
import {
  BuildingLibraryIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { Column, Icon, Row, Separator } from 'ui'
import { MoneyCheck } from 'ui/src/components/Icons/MoneyCheck'

type PaymentDetailsProps = {
  feesCollected: string
  netPayment?: string
  chargedAmount: string
  refundedAmount?: string
  returnedAmount?: string
  headerLabel: string
  linkText?: string
  headerValue?: string
  factoringFee?: string
  onLinkClick?: () => void
  paymentMethod: ReactNode
  billingAddress?: ReactNode
  contactInfo: ReactNode
}

type AdvanceDetailsProps = {
  headerLabel: string
  advanceAmount: string
  headerValue?: string
  borrowerName?: string
  paymentRequestId?: string
}

type PaymentMethodCardProps = {
  brand: string
  last4: string
}

type PaymentMethodAchProps = {
  routingNumber: string
}

type PaymentMethodProps = {
  card?: PaymentMethodCardProps
  achDebit?: PaymentMethodAchProps
}

type CheckProps = {
  checkNumber?: string
}

export const Check = (props: CheckProps) => {
  return (
    <Row y="center" gap="small">
      <Text className="text-sm gray-800">{`Physical Check #${
        !!props.checkNumber && props.checkNumber.length > 0
          ? props.checkNumber
          : 'PROCESSING'
      }`}</Text>
      <MoneyCheck width={20} height={20} />
    </Row>
  )
}

export const PaymentMethod = (_props: PaymentMethodProps) => {
  return _props.card ? (
    <Row y="center" gap="small">
      <Text className="text-sm gray-800">Credit Card</Text>
      <CreditCardOutlined width={20} height={20} />
      <Text className="text-sm gray-800">
        {_props.card?.brand} ·· {_props.card?.last4}
      </Text>
    </Row>
  ) : (
    <Row y="center" gap="small">
      <Text className="text-sm gray-800">ACH/eCheck</Text>
      <BuildingLibraryIcon width={20} height={20} />
    </Row>
  )
}

type ContactInfoProps = {
  name?: string | null
  email?: string | null
}

export const ContactInfo = (_props: ContactInfoProps) => {
  return (
    <Column x="right" gap="xsmall">
      <Text className="text-sm text-gray-800">{_props.name || ''}</Text>
    </Column>
  )
}

type Address = {
  street1?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
}

type BillingAddressProps = {
  companyName?: string | null
  address?: Address | null
}

export const BillingAddress = (_props: BillingAddressProps) => {
  const address = _props.address
  return (
    <Column x="right" gap="xsmall">
      {_props.companyName ? (
        <Text className="text-sm text-gray-800">
          {_props.companyName || ''}
        </Text>
      ) : (
        ''
      )}
      <Text className="text-sm text-gray-800">
        {address?.street1 ? `${address?.street1}, ` : ''}
        {address?.city ? `${address?.city}, ` : ''}
        {address?.state ? `${address?.state}, ` : ''}
        {address?.zip}
      </Text>
    </Column>
  )
}

export const AdvanceDetails = (_props: AdvanceDetailsProps) => {
  const navigate = useNavigate()
  return (
    <Column gap="medium" wGrow>
      <Row grow between rounded y="center">
        <Text fontWeight={'semibold'} color={'dark.800'} fontSize="xl">
          {_props.headerLabel}
        </Text>
        {_props.headerValue && (
          <Text color={'gray.500'}>{_props.headerValue}</Text>
        )}
      </Row>
      <Column
        className="border rounded border-gray-300 py-4 px-3 bg-gray-100"
        gap="medium"
        wGrow
      >
        <Row grow between y="center">
          <Row y="center" gap="small">
            <Text className="text-sm text-gray-500">Advanced</Text>
            <Tooltip
              label="Total amount advanced to you by factoring this invoice"
              fontSize="xs"
              hasArrow
              placement="right"
              bg={'gray.500'}
            >
              <InformationCircleIcon className="w-4 h-4 text-gray-400" />
            </Tooltip>
          </Row>
          <Text className="text-sm text-gray-500">{_props.advanceAmount}</Text>
        </Row>

        <Row grow between y="center">
          <Row y="center" gap="small">
            <Text className="text-sm text-gray-500">Customer</Text>
            <Tooltip
              label="The customer that has accepted net terms"
              fontSize="xs"
              hasArrow
              placement="right"
              bg={'gray.500'}
            >
              <InformationCircleIcon className="w-4 h-4 text-gray-400" />
            </Tooltip>
          </Row>
          <Text className="text-sm text-gray-500">{_props.borrowerName}</Text>
        </Row>

        <Row grow between y="center">
          <Row y="center" gap="small">
            <Text
              className="text-sm text-purple-500"
              cursor={'pointer'}
              onClick={() => {
                navigate(`/dashboard/get-paid/${_props.paymentRequestId}`, {
                  state: { isAdvance: true },
                })
              }}
            >
              Go to Payment Request
            </Text>
          </Row>
        </Row>
      </Column>
    </Column>
  )
}

const PaymentDetails = (_props: PaymentDetailsProps) => {
  return (
    <Column gap="medium" wGrow>
      <Row grow between rounded y="center">
        <Text fontWeight={'semibold'} color={'dark.800'} fontSize="xl">
          {_props.headerLabel}
        </Text>
        {_props.headerValue && (
          <Text color={'gray.500'}>{_props.headerValue}</Text>
        )}
        {_props.linkText && _props.onLinkClick && (
          <Row gap="small" y="center">
            <Text
              color={'purple.500'}
              _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              fontSize={'sm'}
              onClick={_props.onLinkClick}
            >
              {_props.linkText}
            </Text>
            <Icon
              name="arrowTopRightOnSquare"
              className="stroke-purple-600"
              onClick={_props.onLinkClick}
            />
          </Row>
        )}
      </Row>
      <Column
        className="border rounded border-gray-300 py-4 px-3 bg-gray-100"
        gap="medium"
        wGrow
      >
        <Row grow between y="center">
          <Row y="center" gap="small">
            <Text className="text-sm text-gray-500">Charged</Text>
            <Tooltip
              label="Total with fees charged by Nickel"
              fontSize="xs"
              hasArrow
              placement="right"
              bg={'gray.500'}
            >
              <InformationCircleIcon className="w-4 h-4 text-gray-400" />
            </Tooltip>
          </Row>
          <Text className="text-sm text-gray-500">{_props.chargedAmount}</Text>
        </Row>
        <Row grow between y="center">
          <Row y="center" gap="small">
            <Text className="text-sm text-gray-500">
              Nickel Processing Fees
            </Text>
            <Tooltip
              hasArrow
              bg={'gray.500'}
              placement="right"
              label="Fees charged by Nickel"
              fontSize="xs"
            >
              <InformationCircleIcon className="w-4 h-4 text-gray-400" />
            </Tooltip>
          </Row>
          <Text className="text-sm text-gray-500">
            ({_props.feesCollected})
          </Text>
        </Row>
        {_props.factoringFee && (
          <Row grow between y="center">
            <Row y="center" gap="small">
              <Text className="text-sm text-gray-500">Factoring Fees</Text>
              <Tooltip
                hasArrow
                bg={'gray.500'}
                placement="right"
                label="Fees associated with factoring this invoice"
                fontSize="xs"
              >
                <InformationCircleIcon className="w-4 h-4 text-gray-400" />
              </Tooltip>
            </Row>
            <Text className="text-sm text-gray-500">
              ({_props.factoringFee})
            </Text>
          </Row>
        )}
        {_props.refundedAmount && (
          <Row grow between y="center">
            <Text className="text-sm text-gray-500">Refunded</Text>
            <Text className="text-sm text-gray-500">
              ({_props.refundedAmount})
            </Text>
          </Row>
        )}
        {_props.returnedAmount && (
          <Row grow between y="center">
            <Text className="text-sm text-gray-500">Returned</Text>
            <Text className="text-sm text-gray-500">
              ({_props.returnedAmount})
            </Text>
          </Row>
        )}
        {_props.netPayment && (
          <Row grow between y="center">
            <Text className="text-sm text-gray-800 font-semibold">
              Net Payment
            </Text>
            <Text className="text-sm text-gray-800 font-semibold">
              {_props.netPayment}
            </Text>
          </Row>
        )}
        <Separator orientation="horizontal" />
        <Row grow between y="center">
          <Text className="text-sm text-gray-800">Payment Method</Text>
          {_props.paymentMethod}
        </Row>
        {_props.billingAddress && (
          <Row grow between y="top">
            <Text className="text-sm text-gray-800">Billing Address</Text>
            {_props.billingAddress}
          </Row>
        )}
        <Row grow between y="center">
          <Text className="text-sm text-gray-800">Name</Text>
          {_props.contactInfo}
        </Row>
      </Column>
    </Column>
  )
}

export default PaymentDetails
