import { useNavigate } from 'react-router-dom'
import { Button, VFlex } from 'ui'
import BillPaymentReceipt from './BillPaymentReceipt'
import { useSendPaymentStore } from './SendPaymentRouter'

const BillReceipt = () => {
  const { billPaymentId, reset } = useSendPaymentStore()
  const navigate = useNavigate()
  return (
    <VFlex gap={8}>
      <VFlex gap={4}>
        <BillPaymentReceipt
          {...{
            paymentId: billPaymentId || '',
            route: '/api/pdf',
          }}
        />
        <Button
          label="Return to Dashboard"
          variant="outline"
          size="lg"
          onClick={() => {
            reset()
            navigate('/dashboard/accounts-payable/bills')
          }}
        />
      </VFlex>
    </VFlex>
  )
}

export default BillReceipt
