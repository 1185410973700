import { useMutation, useQuery } from '@apollo/client'
import { ActivityNote, QueryOptsActivityNote } from '../ap/ActivityNoteModal'
import { Box, Divider, Flex, Text, useToast } from '@chakra-ui/react'
import { Button, Card, Column, HFlex, OverlaySectionReturn } from 'ui'
import {
  CancelBillPaymentDocument,
  GetBillPaymentDocument,
  PaymentActivitySource,
} from '../../operations-types'
import currency from 'currency.js'
import {
  getTransactionStatusFromActivity,
  reportErrorIfExists,
} from '../../lib/utils'
import AdditionalPaymentDetail from '../payments/AdditionalPaymentDetail'

import { OverlayHeaderV2 } from '../payments/OverlayHeader'
import AmountHeader from '../payments/PaymentAmountSubmitted'
import PaymentDetails, {
  Check,
  ContactInfo,
  PaymentMethod,
} from '../payments/PaymentDetails'
import PaymentElements from '../payments/PaymentElements'
import BillPaymentDetails from './components/BillPaymentDetails'
import {
  paymentMethodIntoIdentifier,
  vendorPayoutOrBankAccountIntoIdentifier,
} from './PaymentInnerOverlay'
import { TransactionActivity } from './TransactionActivity'
import moment from 'moment'

type PayableInnerOverlayProps = {
  billPaymentId: string
  exitOverlay: () => void
  onPayableClick?: (billPaymentId: string) => void
  onBack?: () => void
  queryOpts?: QueryOptsActivityNote
  onReturnItem: (id: string) => void
}

export default function PayableInnerOverlay({
  exitOverlay,
  onBack,
  billPaymentId,
  queryOpts,
  onPayableClick,
  onReturnItem,
}: PayableInnerOverlayProps) {
  const { loading, data, error } = useQuery(GetBillPaymentDocument, {
    variables: { billPaymentId: billPaymentId },
  })
  const toaster = useToast()
  const [cancelBillPayment, { loading: cancelBillLoading }] = useMutation(
    CancelBillPaymentDocument,
    {
      refetchQueries: ['paymentActivities'],
      onCompleted: (data) => {
        if (data?.cancelBillPayment?.error?.message) {
          toaster({
            title: 'Error',
            description: data.cancelBillPayment.error.message,
            status: 'error',
          })
          return
        }

        toaster({
          title: 'Payment Cancelled',
          status: 'success',
        })
        exitOverlay()
      },
    },
  )
  reportErrorIfExists(data?.billPayment?.error?.message || error)

  if (loading) {
    return (
      <Column x="center" y="center" grow wGrow>
        <img src="/3-dots-fade.svg" alt="spinner" />
      </Column>
    )
  }

  const billPayment = data?.billPayment?.billPayment
  const payment = billPayment
  const payout = billPayment?.charges?.slice(-1)[0].vendorPayout

  const payoutMethod = billPayment?.vendorPayoutMethod
  const paymentMethod = billPayment?.paymentMethod

  const netPayment =
    (payment?.amountInCents || 0) - (payment?.platformFeeInCents || 0)

  return (
    <Flex flexDirection="column" w="100%" h="100%">
      <OverlayHeaderV2
        onClose={exitOverlay}
        onBack={onBack}
        transactionStatus={
          getTransactionStatusFromActivity(
            billPayment?.activity?.status || '',
            billPayment?.activity?.flags || [],
          ).status
        }
      />
      <AmountHeader
        label={'Payment Amount'}
        amount={currency(netPayment, { fromCents: true }).format()}
      />
      <TransactionActivity
        payoutMethod={vendorPayoutOrBankAccountIntoIdentifier(
          payoutMethod || null,
          payout?.paidAt || null,
          'Sent',
        )}
        paymentMethod={paymentMethodIntoIdentifier(
          billPayment?.paymentMethod || null,
          false,
          billPayment?.createdAt || '',
          'Initiated',
        )}
        completed={!!payout?.paidAt}
      />
      <PaymentElements
        {...{
          paymentNode: (
            <Column gap="large" wGrow className="w-full">
              <PaymentDetails
                {...{
                  headerLabel: 'Your Payment',
                  linkText:
                    billPayment?.billPayable.type === 'BILL'
                      ? 'View Bill'
                      : undefined,
                  onLinkClick: onPayableClick
                    ? () =>
                        onPayableClick(
                          billPayment?.billPayable?.bills?.at(0)?.id!,
                        )
                    : undefined,
                  feesCollected: currency(
                    billPayment?.platformFeeInCents || 0,
                    { fromCents: true },
                  ).format(),
                  chargedAmount: currency(billPayment?.amountInCents || 0, {
                    fromCents: true,
                  }).format(),
                  netPayment: currency(
                    (billPayment?.amountInCents || 0) -
                      (billPayment?.platformFeeInCents || 0),
                    { fromCents: true },
                  ).format(),
                  paymentMethod: paymentMethod?.card?.last4 ? (
                    <PaymentMethod
                      card={{
                        last4: paymentMethod?.card?.last4,
                        brand: paymentMethod?.card?.brand || '',
                      }}
                    />
                  ) : (
                    <PaymentMethod
                      achDebit={{
                        routingNumber: paymentMethod?.achDebit?.routingNumber!,
                      }}
                    />
                  ),
                  contactInfo: (
                    <ContactInfo name={paymentMethod?.billingDetails?.name} />
                  ),
                }}
              />
              <BillPaymentDetails
                {...{
                  headerLabel: 'Vendor Payment',
                  onLinkClick: onPayableClick
                    ? () => onPayableClick(payment?.id!)
                    : undefined,
                  billPaymentAmount: currency(netPayment || 0, {
                    fromCents: true,
                  }).format(),
                  paymentMethod: payoutMethod?.routingNumber ? (
                    <PaymentMethod
                      achDebit={{
                        routingNumber:
                          payoutMethod?.accountNumber?.slice(-2) || '',
                      }}
                    />
                  ) : (
                    <Check checkNumber={payout?.checkNumber || ''} />
                  ),
                  vendorName: billPayment?.billPayable?.vendor?.name || '',
                  deliveredTo: payoutMethod?.routingNumber
                    ? `${
                        payoutMethod.bankName
                      } ·· ${payoutMethod.accountNumber?.slice(-2)}`
                    : `${payoutMethod?.street}, ${payoutMethod?.city}, ${payoutMethod?.state} ${payoutMethod?.zip}`,
                }}
              />
              <Column className="w-full" gap="medium">
                <Text fontWeight="semibold" color="dark.800" fontSize="xl">
                  Purpose
                </Text>
                <Card className="w-full p-2 px-4">
                  {billPayment?.billPayable.invoiceNumber || ''}
                </Card>
              </Column>

              {billPayment?.charges?.[0]?.vendorPayout?.achVendorReturn && (
                <OverlaySectionReturn
                  achReturn={{
                    id: billPayment?.charges?.[0]?.vendorPayout?.achVendorReturn
                      .id,
                    amount: currency(
                      billPayment?.charges?.[0]?.vendorPayout?.achVendorReturn
                        .amountCents,
                      {
                        fromCents: true,
                      },
                    ).value,
                    paidAt: billPayment?.charges?.[0]?.vendorPayout
                      ?.achVendorReturn.payout?.paidAt
                      ? moment(
                          billPayment?.charges?.[0]?.vendorPayout
                            ?.achVendorReturn.payout?.paidAt,
                        ).format('MM/DD/YYYY')
                      : undefined,
                    status: billPayment?.charges?.[0]?.vendorPayout
                      ?.achVendorReturn.payout?.paidAt
                      ? 'Completed'
                      : 'Pending',
                  }}
                  onReturnClick={onReturnItem}
                />
              )}
              <ActivityNote
                {...{
                  activityId: billPayment?.activity?.id || '',
                  initialNote: billPayment?.activity?.note?.note || '',
                  sourceType: PaymentActivitySource.Refund,
                  reconciled: billPayment?.activity?.note?.reconciled || false,
                  flagged: billPayment?.activity?.note?.flagged || false,
                  queryOpts,
                }}
              />
            </Column>
          ),
          detailNode: (
            <AdditionalPaymentDetail
              {...{
                reference: {
                  billPaymentId: billPayment?.id || '',
                  reference: billPayment?.billPayable.invoiceNumber || '',
                },
              }}
            />
          ),
        }}
      />
      {billPayment?.activity?.status === 'PENDING' && (
        <Box mt="auto">
          <Divider />
          <HFlex justifyContent="flex-end" px={8} py={4}>
            <Button
              label="Cancel Payment"
              isLoading={cancelBillLoading}
              onClick={() =>
                cancelBillPayment({ variables: { billPaymentId } })
              }
            />
          </HFlex>
        </Box>
      )}
    </Flex>
  )
}
