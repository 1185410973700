import { Flex } from '@chakra-ui/react'
import { GoBackButton, PaymentOption } from 'ui'
import { PaymentMethod } from '../../../operations-types'
import PlaidLink from '../../onboardingV2/PlaidLink'
import { getFeeString } from '../SchedulePayment'

type PaymentMethodPickerProps = {
  onPayByCardClicked: () => void
  onPayByBankClicked: () => void
  onGoBackClicked: () => void
  onBankAccountAdded: (paymentMethod: PaymentMethod) => void
  achEnabled?: boolean
  cardEnabled?: boolean
  setLoading?: (loading: boolean) => void
}

export function PaymentMethodPicker({
  onPayByCardClicked,
  onPayByBankClicked,
  onGoBackClicked,
  achEnabled = true,
  cardEnabled = true,
  onBankAccountAdded,
  setLoading,
}: PaymentMethodPickerProps) {
  return (
    <Flex flexDirection="column" gap={4}>
      {cardEnabled && (
        <PaymentOption
          title="Add a Card"
          icon="creditCard"
          feeString={getFeeString()}
          selected={false}
          onClick={onPayByCardClicked}
          description="Your payment arrives the next business day."
        />
      )}

      {achEnabled && (
        <PlaidLink
          methodType="payment"
          onAddPaymentMethod={onBankAccountAdded}
          setLoading={setLoading}
          component={
            <PaymentOption
              title="Add a Bank Account"
              icon="wallet"
              feeString="No Fees"
              selected={false}
              onClick={onPayByBankClicked}
              description="Your payment arrives in 2-3 business days."
            />
          }
        />
      )}
      <GoBackButton onClick={onGoBackClicked} />
    </Flex>
  )
}
