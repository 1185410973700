import { Helmet } from 'react-helmet-async'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Sidebar, SidebarSectionProps } from 'ui'
import { useAuth } from '../../lib/auth'
import {
  NEW_CLIENT_URL,
  PAY_VENDORS_APPROVALS_URL,
  PAY_VENDORS_BILLS_URL,
  TRANSACTIONS_URL,
} from '../../lib/urls'
import {
  BecomeUserDocument,
  RevertToAccountingAccountDocument,
  SelfQuery,
} from '../../operations-types'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useMutation } from '@apollo/client'
import { AWS_BUCKET_ROUTE } from '../../lib/utils'
import { show } from '@intercom/messenger-js-sdk'
import posthog from 'posthog-js'

type User = NonNullable<NonNullable<SelfQuery['user']>['user']>

type LoggedInLayoutProps = {
  loggedInUser: User
}

export default function LoggedInLayout({ loggedInUser }: LoggedInLayoutProps) {
  const { signOut, setAuth, getAccountantUserId, getAdminUserId } = useAuth()
  const accountantUserId = getAccountantUserId()
  const location = useLocation()
  const nickelCreditEnabled = useFeatureFlagEnabled('nickelCreditEnabled')
  const [becomeUser] = useMutation(BecomeUserDocument, {
    onCompleted: (data) => {
      const token = data?.becomeUser?.token

      setAuth(token)
    },
  })
  const [revertToAccountingFirm] = useMutation(
    RevertToAccountingAccountDocument,
    {
      onCompleted: (data) => {
        const token = data?.revertToAccountingAccount?.token

        setAuth(token)
      },
    },
  )

  const isLoggedIntoAccountingFirm =
    loggedInUser.organization?.role === 'ACCOUNTING_FIRM'
  const adminUserId = getAdminUserId()
  const clients = loggedInUser.organization?.clients || []
  const sidebarSectionRoutes: Array<SidebarSectionProps> = [
    {
      label: 'Summary',
      url: '/dashboard',
      icon: 'sparkles',
      onClick: () => navigate('/dashboard'),
    },
    nickelCreditEnabled
      ? {
          label: 'Trade Accounts',
          url: '/dashboard/trade-accounts',
          icon: 'user',
          onClick: () => navigate('/dashboard/trade-accounts'),
        }
      : null,
    accountantUserId || isLoggedIntoAccountingFirm
      ? {
          label: 'Clients',
          url: '/dashboard/clients',
          icon: 'squaresPlus',
          onClick: () => navigate('/dashboard/clients'),
        }
      : null,
    {
      label: 'Transactions',
      url: TRANSACTIONS_URL,
      icon: 'creditCard',
      onClick: () => navigate(TRANSACTIONS_URL),
    },
    {
      label: 'Get Paid',
      url: '/dashboard/get-paid',
      icon: 'currencyDollar',
      onClick: () => navigate('/dashboard/get-paid'),
    },
    {
      label: 'Pay Vendors',
      url: PAY_VENDORS_BILLS_URL,
      icon: 'paperPlane',
      onClick: () => navigate(PAY_VENDORS_BILLS_URL),
      subsections: [
        {
          label: 'Bills',
          url: PAY_VENDORS_BILLS_URL,
          onClick: () => navigate(PAY_VENDORS_BILLS_URL),
        },
        {
          label: 'Approvals',
          url: PAY_VENDORS_APPROVALS_URL,
          onClick: () => navigate(PAY_VENDORS_APPROVALS_URL),
        },
      ],
    },
  ].filter(Boolean) as Array<SidebarSectionProps>

  const subSectionUrls = sidebarSectionRoutes.flatMap(
    (sr) => sr.subsections?.map((x) => x.url),
  )
  const urls = sidebarSectionRoutes.map((x) => x.url)

  const allUrls = [...urls, ...subSectionUrls, '/dashboard/settings']

  const selected =
    allUrls.filter((url) => url === location.pathname).at(0) || ''

  const routes = [...sidebarSectionRoutes]

  if (loggedInUser.role === 'ADMIN') {
    routes.push({
      label: 'Admin',
      url: '/dashboard/admin',
      icon: 'power',
      onClick: () => navigate('/dashboard/admin'),
    })

    routes.push({
      label: 'Payment Links',
      url: '/dashboard/payment-links',
      onClick: () => navigate('/dashboard/payment-links'),
      icon: 'link',
    })
  }

  const navigate = useNavigate()

  const sidebarRoutes = [
    {
      label: 'Settings',
      url: '/dashboard/settings',
      onClick: () => navigate('/dashboard/settings'),
    },
    {
      label: 'Logout',
      onClick: () => {
        posthog.reset()
        signOut()
      },
    },
  ]

  const getAccountingFirmProps = () => {
    if (isLoggedIntoAccountingFirm) {
      return {
        id: loggedInUser.organization?.id || '',
        name: loggedInUser.organization?.name || '',
        email: loggedInUser.email || '',
        src: loggedInUser.organization?.accountInfo?.logoKey
          ? `${AWS_BUCKET_ROUTE}${loggedInUser.organization?.accountInfo?.logoKey}`
          : undefined,

        isSelected: true,
      }
    }
    const accountant = loggedInUser?.organization?.accountant

    return {
      id: accountant?.organization?.id || '',
      name: accountant?.organization?.name || '',
      email: accountant?.user?.email || '',
      src: accountant?.organization?.accountInfo?.logoKey
        ? `${AWS_BUCKET_ROUTE}${accountant.organization?.accountInfo?.logoKey}`
        : undefined,
      isSelected: false,
    }
  }

  return (
    <div>
      <Helmet>
        <title>Nickel</title>
      </Helmet>
      <main className="w-full">
        <div className="flex flex-col h-screen max-h-screen">
          <div className="flex w-full grow items-start">
            <Sidebar
              accountName={
                accountantUserId
                  ? loggedInUser?.organization?.accountant?.user?.firstName ||
                    'Account'
                  : loggedInUser.firstName || 'Account'
              }
              label={loggedInUser.organization?.name || ''}
              footerOptions={sidebarRoutes}
              sections={routes}
              onRouteChange={(route) => navigate(route)}
              onHeaderClick={() => navigate('/dashboard')}
              currentUrl={selected}
              isDemo={import.meta.env.VITE_NODE_ENV === 'demo'}
              organizationTier={
                loggedInUser.organization?.accountInfo?.tier || 'FREE_TIER'
              }
              logoUrl={
                loggedInUser.organization?.accountInfo?.logoKey
                  ? `${AWS_BUCKET_ROUTE}${loggedInUser.organization?.accountInfo?.logoKey}`
                  : undefined
              }
              planUrl={
                import.meta.env.MODE === 'development'
                  ? 'http://localhost:3000/dashboard/nickel-plans'
                  : import.meta.env.MODE === 'production'
                  ? 'https://app.getnickel.com/dashboard/nickel-plans'
                  : 'https://app.staging.getnickel.com/dashboard/nickel-plans'
              }
              footerSections={
                [
                  adminUserId
                    ? {
                        label: 'Revert',
                        icon: 'arrowUTurnLeft',
                        url: '/dashboard/revert-to-account',
                        onClick: async () => {
                          await revertToAccountingFirm({
                            variables: {
                              admin: true,
                            },
                          })
                        },
                      }
                    : false,
                  {
                    label: 'Account Settings',
                    icon: 'cog',
                    url: '/dashboard/settings',
                    onClick: () => navigate('/dashboard/settings'),
                  },
                  {
                    label: 'Help Center',
                    icon: 'questionMarkCircle',
                    url: '/dashboard/help-center',
                    onClick: () => show(),
                  },
                ].filter(Boolean) as Array<SidebarSectionProps>
              }
              switcher={
                accountantUserId || isLoggedIntoAccountingFirm
                  ? {
                      currentAccount: {
                        name: loggedInUser.organization?.name || '',
                        src: loggedInUser.organization?.accountInfo?.logoKey
                          ? `${AWS_BUCKET_ROUTE}${loggedInUser.organization?.accountInfo?.logoKey}`
                          : undefined,
                      },
                      accountingFirm: getAccountingFirmProps(),
                      clients: clients.map((x) => {
                        return {
                          id: x.id,
                          name: x.name || '',
                          src: x.accountInfo?.logoKey
                            ? `${AWS_BUCKET_ROUTE}${x.accountInfo?.logoKey}`
                            : undefined,
                          isSelected: loggedInUser.organization?.id === x.id,
                        }
                      }),
                      onClickAccountingFirm: async () => {
                        if (isLoggedIntoAccountingFirm) {
                          return
                        }
                        await revertToAccountingFirm()
                      },
                      onClickClient: async (client) => {
                        const org = clients.find((x) => x.id === client.id)
                        const owner = org?.employees?.find(
                          (x) => x.role === 'USER_ADMIN',
                        )

                        if (!owner) {
                          console.error('No owner found for client')

                          const firstDude = org?.employees?.at(0)

                          if (!firstDude) {
                            console.error('No employees found for client!')
                            return
                          }

                          await becomeUser({
                            variables: {
                              becomeUserId: firstDude.id,
                            },
                          })

                          return
                        }

                        await becomeUser({
                          variables: {
                            becomeUserId: owner.id,
                          },
                        })
                      },
                      onClickAddNewClient: async () => {
                        if (!isLoggedIntoAccountingFirm) {
                          await revertToAccountingFirm()
                        }
                        navigate(NEW_CLIENT_URL)
                      },
                    }
                  : undefined
              }
            />
            <div className="ml-[240px] flex align-center flex-col grow max-h-screen transition-all duration-300 ease-in-out">
              <Outlet context={loggedInUser} />
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
